/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@6.0.4/swiper-bundle.min.js
 * - /npm/video.js@7.8.4/dist/video.min.js
 * - /npm/video.js@7.8.4/dist/lang/el.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/awesomplete@1.1.5/awesomplete.min.js
 * - /npm/micromodal@0.4.6/dist/micromodal.min.js
 * - /npm/list.js@2.3.1/dist/list.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
